import React, { useContext } from "react";
import Button from "../components/Button.js";
import { useNavigate } from "react-router-dom";
import CheckboxList from "../components/Checkbox.js";
import SweetAlert from "../components/SweetAlert.js";
import ProjectTypeComponent from "../components/Form/ProjectType.js";
import { FormDataContext } from "../ContextApi/FormDataContext.js";
import Client from "../components/Form/Client.js";
import SowFileUpload from "../components/Form/SowUpload.js";
import AddOtherCost from "../components/Form/AddOtherCost.js";
import OtherCost from "../components/Form/OtherCost.js";
import TranslationCost from "../components/Form/TranslationCost.js";
import EndDate from "../components/Form/EndDate.js";
import StartDate from "../components/Form/StartDate.js";
import ProjectManager from "../components/Form/ProjectManager.js";
import SetupFee from "../components/Form/SetupFee.js";
import CostPerInterview from "../components/Form/CostPerInterview.js";
import SampleSize from "../components/Form/SampleSize.js";
import ProjectName from "../components/Form/ProjectName.js";
import { useSelector } from "react-redux";
import AddMultipleSample from "../components/Form/AddMultipleSample.js";
import IsMultipleSample from "../components/Form/IsMultipleSample.js";
import { toggleMultipleSampleCpi } from "../../utils/slices/AddMutipleSampleCpiSlice.js";
import { useDispatch } from "react-redux";
import MultipleSampleCpiRecord from "../components/Form/MultipleSampleCpiRecord.js";
import { usePostFormData } from "../../utils/hooks/usePostFormData.js";
import Popup from "../components/Popup.js";
import useProjectData from "../../utils/hooks/useProjectData.js";
import { addReRender } from "../../utils/slices/ReRenderSlice.js";

const Form = () => {
  const {
    formData,
    setAdvancePAyment,
    isOtherFee,
    otherCost,
    translationCost,
    SetProjectAdded,
    setFormData,
  } = useContext(FormDataContext);

  const isMultipleSample = useSelector(
    (store) => store.addMultipleSampleCpi.isMultipleSample
  );
  const isMultipleSampleCheckbox = useSelector(
    (store) => store.addMultipleSampleCpi.isMultipleSampleCheckbox
  );

  const dispatchAddMultipleSampleCpi = useDispatch();
  const dispatchAddMultipleSampleCpiCheckbox = useDispatch();

  const navigate = useNavigate();
  const darkMode = useSelector((store) => store.darkMode.isDarkMode);

  const handleCheckboxChange = (name, checked) => {
    setAdvancePAyment(checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    if (!isFormValid()) {
      return SweetAlert({
        title: "Warning",
        text: "Fill all required fields",
        icon: "warning",
      });
    } else {
      usePostFormData(
        formData,
        SetProjectAdded,
        dispatchAddMultipleSampleCpi,
        dispatchAddMultipleSampleCpiCheckbox,
        navigate,
        setFormData
      );
      dispatchAddMultipleSampleCpi(addReRender(2));
    }
  };

  const isFormValid = () => {
    return (
      formData.name !== "" &&
      formData.project_type?.name !== "" &&
      formData.clients?.name !== "" &&
      formData.sample !== "" &&
      (isMultipleSampleCheckbox
        ? formData.cpi == "" || formData.cpi == 0 || formData.cpi == null
        : formData.cpi !== "") &&
      formData.set_up_fee !== "" &&
      formData.tentative_start_date !== "" &&
      formData.tentative_end_date !== "" &&
      formData.project_samples !== ""
    );
  };
  const handleCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div
      className={`${
        darkMode ? "bg-black text-white" : "bg-white text-black"
      } relative rounded-md shadow-lg shadow-slate-600`}
    >
      <h2 className="text-3xl p-8 mt-8 underline">Add Project Details</h2>
      <form
        onSubmit={(e) => handleSubmit(e, formData)}
        className="lg:p-2 lg:pl-8 lg:pr-4 pr-8"
        encType="multipart/form-data"
        id="1234"
      >
        <div className="lg:flex inline-block lg:flex-wrap flex-nowrap w-full gap-4">
          <div className="flex flex-col lg:w-[32%] w-full">
            <ProjectName />
          </div>
          <div className="flex flex-col lg:w-[32%] w-full">
            <ProjectTypeComponent />
          </div>
          <div className="flex flex-col lg:w-[32%] w-full">
            <Client />
          </div>
          <div className="flex flex-col lg:w-[32%] w-full">
            <SampleSize />
            <IsMultipleSample />
          </div>
          {isMultipleSample && (
            <Popup>
              <AddMultipleSample />
            </Popup>
          )}
          <div className="lg:w-[32%] w-full flex flex-col">
            <CostPerInterview />
          </div>
          <div className="lg:w-[32%] w-full flex flex-col relative">
            <SetupFee />
          </div>
          {isOtherFee && (
            <Popup>
              <AddOtherCost />
            </Popup>
          )}
          {otherCost && <OtherCost />}
          {translationCost && <TranslationCost />}
          <div className="flex flex-col lg:w-[32%] w-full">
            <ProjectManager />
          </div>
          <div className="flex flex-col lg:w-[32%] w-full">
            <StartDate />
          </div>
          <div className="flex flex-col lg:w-[32%] w-full">
            <EndDate />
          </div>
          <div className="flex flex-col lg:w-[32%] w-full">
            <SowFileUpload />
          </div>
          <div className="flex w-full">
            <MultipleSampleCpiRecord />
          </div>
        </div>
        <div className="flex flex-col lg:w-[32%] w-full pt-8 pb-2">
          <CheckboxList
            InputItems={["Advanced Payment Required"]}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>
        <div className="flex justify-around pt-4 pb-2 md:w-4/12 w-6/12 text-center">
          <Button
            className={`bg-green-500 p-4 mt-8 mr-2 md:w-1/2 w-full text-white font-bold ${
              isFormValid() ? "" : "opacity-50 cursor-not-allowed"
            }`}
            name={"Submit"}
            onClick={(e) => handleSubmit(e, formData)}
          />
          {/* <Link
            to={"/sales-dashboard"}
            className="bg-red-500 p-4 mt-8 md:w-1/2 w-full text-white font-bold"
          > */}
          <Button
            className="bg-red-500 p-4 mt-8 md:w-1/2 w-full text-white font-bold"
            name={"Cancel"}
            onClick={handleCancel}
          />
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};
export default Form;
