import { Drawer } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import Accordion from "./Accordian";
import FilterOptionSelected from "./FilterOptionSelected";
import { FilterContext } from "../ContextApi/FilterContext";
import { useSelector } from "react-redux";
import useUserData from "../../utils/hooks/useUserData";
import { allManagerRolesRole, isHodRole, userRole } from "../config/Role";
import { useDispatch } from "react-redux";
import { addSelectedHod } from "../../utils/slices/SelectedHodSlice";
import {
  addSelectedManager,
  addSelectedManagerListArray,
} from "../../utils/slices/SelectedManagerSlice";

const FilterDrawer = ({
  setOpenFilter,
  selectedOptions,
  setSelectedOptions,
  openFilter,
}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedTl, setSelectedTl] = useState(null);
  const [selectedHod, setSelectedHod] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(openFilter);
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  const {
    // selectedHod,
    // setSelectedHod,
    // selectedManager,
    // setSelectedManager,
    // setSelectedTl,
    clientsList,
    managerListArray,
    tlListArray,
  } = useContext(FilterContext);

  const darkMode = useSelector((store) => store.darkMode.isDarkMode);
  const dispatchSelectedHod = useDispatch();
  const dispatchSelectedManagerListArray = useDispatch();
  const dispatchSelectedManager = useDispatch();
  const role = localStorage.getItem("role");

  // const { clientsList, managerListArray, tlListArray } =
  //   useContext(FilterContext);
  const userData = useUserData();

  const filteredHodsList = userData.filter(
    (item) => item?.role?.name === "HOD"
  );

  useEffect(() => {
    const managerList =
      !selectedHod && !isHodRole
        ? managerListArray
        : selectedHod
        ? userData.filter((user) => user.reports_to?.name == selectedHod)
        : isHodRole
        ? userData.filter((user) => user.reports_to?.id == userRole)
        : managerListArray;

    setFilteredManagerList(managerList);
    dispatchSelectedManagerListArray(addSelectedManagerListArray(managerList));
  }, [
    selectedHod,
    isHodRole,
    userData,
    managerListArray,
    userRole,
    isDrawerOpen,
  ]);

  const filteredTeamLeadList = selectedManager
    ? userData.filter((tl) => tl.reports_to?.name == selectedManager)
    : allManagerRolesRole
    ? userData.filter((tl) => tl.reports_to?.id == userRole)
    : tlListArray;

  const handleOptionChange = (name, updatedOptions) => {
    setSelectedOptions(updatedOptions.value);

    if (name === "HOD") {
      setSelectedHod(updatedOptions.value ? updatedOptions.value : null);
      setSelectedManager(null);
      dispatchSelectedHod(addSelectedHod(updatedOptions.value));
    }

    if (name === "Manager") {
      setSelectedManager(updatedOptions.value ? updatedOptions.value : null);
      setSelectedTl(null);
      dispatchSelectedManager(addSelectedManager(updatedOptions.value));
    }
  };

  const handleAccordionToggle = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleClearAllSelection = () => {
    setSelectedOptions([]);
    setSelectedHod(null);
    setSelectedManager(null);
  };

  const accordionData = [
    {
      title: "Client",
      options: clientsList?.map((item) => item.name),
      name: "Client",
    },
    {
      title: "HOD",
      options: filteredHodsList?.map((item) => item?.user_role?.name),
      name: "HOD",
    },
    {
      title: "MANAGER",
      options: filteredManagerList?.map((item) => item?.user_role?.name),
      name: "Manager",
    },
    {
      title: "Team Lead",
      options: filteredTeamLeadList?.map((item) => item?.user_role?.name),
      name: "Team Lead",
    },
  ];

  const visibleAccordionsByRole = {
    Director: ["Client", "HOD", "MANAGER"],
    HOD: ["Client", "MANAGER", "Team Lead"],
    Manager: ["Client", "Team Lead"],
    "Team Lead": ["Client"],
  };

  const visibleAccordions = visibleAccordionsByRole[role] || [];

  return (
    <div
      className={`${darkMode ? "bg-black text-white" : "bg-white text-black"}`}
    >
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setOpenFilter(false);
        }}
        PaperProps={{ sx: { width: 400, padding: 2 } }}
      >
        <div
          className={`no-scrollbar ${
            darkMode ? "bg-black text-white" : "bg-white text-black"
          }`}
        >
          <h1 className="text-blue-500 pb-4 font-bold text-xl">
            Filter Project
          </h1>
          <FilterOptionSelected
            selectedItems={selectedOptions}
            setSelectedItems={setSelectedOptions}
            handleClearAllSelection={handleClearAllSelection}
          />

          {accordionData
            .filter((accordion) => visibleAccordions.includes(accordion.title))
            .map((accordion, index) => (
              <Accordion
                key={index}
                title={accordion.title}
                options={accordion.options}
                onChange={handleOptionChange}
                name={accordion.name}
                isOpen={openAccordionIndex === index}
                onToggle={() => handleAccordionToggle(index)}
                selectedOptions={selectedOptions}
              />
            ))}
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
