// import { createContext, useContext, useState } from "react";
// import { isSalesDept } from "../config/Departments";

// export const FilterContext = createContext();

// export const SearchFilterContext = ({ children }) => {
//   const department = localStorage.getItem("department");
//   console.log(department == isSalesDept);

//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedHod, setSelectedHod] = useState("");
//   const [selectedManager, setSelectedManager] = useState("");
//   const [selectedTl, setSelectedTl] = useState("");
//   const [selectedClient, setSelectedClient] = useState("");
//   const [tlAssociates, setTlAssociates] = useState([]);
//   const [activeTabValue, setActiveTabValue] = useState(
//     department == isSalesDept ? "all" : "In Progress"
//   );
//   const [status, setStatus] = useState([
//     activeTabValue,
//     "All",
//     "To Be Started",
//     "Completed",
//     "CBR Raised",
//     "On Hold",
//   ]);
//   const [selectedStatus, setSelectedStatus] = useState(activeTabValue);
//   const [clientsList, setClientsList] = useState([]);
//   const [managerListArray, setManagerListArray] = useState([]);
//   const [hodListArray, setHodListArray] = useState([]);
//   const [tlListArray, setTlListArray] = useState([]);
//   const [projectData, setProjectData] = useState([]);
//   const [filteredProjectData, setFilteredProjectData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [teamLeadAssiged, setTeamLeadAssiged] = useState();
//   const [clientListDataWithId, setClientListDataWithId] = useState([]);
//   const [clientListData, setClientListData] = useState([
//     "demo Client1",
//     "demo Cliet2",
//   ]);
//   return (
//     <FilterContext.Provider
//       value={{
//         selectedStatus,
//         setSelectedStatus,
//         selectedClient,
//         setSelectedClient,
//         setSearchTerm,
//         searchTerm,
//         setSelectedHod,
//         selectedHod,
//         selectedManager,
//         setSelectedManager,
//         setSelectedTl,
//         selectedTl,
//         tlAssociates,
//         setTlAssociates,
//         status,
//         setStatus,
//         activeTabValue,
//         setActiveTabValue,
//         clientsList,
//         setClientsList,
//         projectData,
//         setProjectData,
//         filteredProjectData,
//         setFilteredProjectData,
//         isLoading,
//         setIsLoading,
//         teamLeadAssiged,
//         setTeamLeadAssiged,
//         clientListDataWithId,
//         setClientListDataWithId,
//         clientListData,
//         setClientListData,
//         hodListArray,
//         setHodListArray,
//         managerListArray,
//         setManagerListArray,
//         tlListArray,
//         setTlListArray,
//       }}
//     >
//       {children}
//     </FilterContext.Provider>
//   );
// };

import { createContext, useContext, useState, useEffect } from "react";
import { isSalesDept } from "../config/Departments";

export const FilterContext = createContext();

export const SearchFilterContext = ({ children }) => {
  const [department, setDepartment] = useState(
    localStorage.getItem("department")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const newDepartment = localStorage.getItem("department");
      setDepartment(newDepartment);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedHod, setSelectedHod] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedTl, setSelectedTl] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [tlAssociates, setTlAssociates] = useState([]);
  const [activeTabValue, setActiveTabValue] = useState(
    department === isSalesDept ? "all" : "In Progress"
  );

  const [status, setStatus] = useState([
    activeTabValue,
    "All",
    "To Be Started",
    "Completed",
    "CBR Raised",
    "On Hold",
  ]);
  const [selectedStatus, setSelectedStatus] = useState(activeTabValue);
  const [clientsList, setClientsList] = useState([]);
  const [managerListArray, setManagerListArray] = useState([]);
  const [hodListArray, setHodListArray] = useState([]);
  const [tlListArray, setTlListArray] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [teamLeadAssiged, setTeamLeadAssiged] = useState();
  const [clientListDataWithId, setClientListDataWithId] = useState([]);
  const [clientListData, setClientListData] = useState([
    "demo Client1",
    "demo Cliet2",
  ]);

  return (
    <FilterContext.Provider
      value={{
        selectedStatus,
        setSelectedStatus,
        selectedClient,
        setSelectedClient,
        setSearchTerm,
        searchTerm,
        setSelectedHod,
        selectedHod,
        selectedManager,
        setSelectedManager,
        setSelectedTl,
        selectedTl,
        tlAssociates,
        setTlAssociates,
        status,
        setStatus,
        activeTabValue,
        setActiveTabValue,
        clientsList,
        setClientsList,
        projectData,
        setProjectData,
        filteredProjectData,
        setFilteredProjectData,
        isLoading,
        setIsLoading,
        teamLeadAssiged,
        setTeamLeadAssiged,
        clientListDataWithId,
        setClientListDataWithId,
        clientListData,
        setClientListData,
        hodListArray,
        setHodListArray,
        managerListArray,
        setManagerListArray,
        tlListArray,
        setTlListArray,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
